import { createApp } from 'vue';
import Antd from 'ant-design-vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as echarts from 'echarts';
import App from './App.vue';
import router from './router';
import store from './store';

// import echarts
import '@/assets/fonts/fonts.css';

const app = createApp(App);

app.config.globalProperties.$echarts = echarts;

app.use(Antd).use(store).use(router).mount('#app');
