<template>
  <div
    class="main"
    :style="{
      transform: `scale(${ratio}, ${ratio}) !important`,
      width: `${appWidth}px`
    }"
  >
    <div class="header">
      <div class="logo-container">
        <img class="logo" src="@/assets/images/logo.png"  alt=""/>
        <div class="title">邦盛数联</div>
      </div>
      <div class="menu-container">
        <div class="menu-tag">
          <div :class="active === 0 ? 'menu active' : 'menu'"
               role="button"
               @click="clickMenu(0)"
               @keydown.enter="clickMenu(0)"
               @keydown.space="clickMenu(0)"
          >首页
          </div>
          <div :class="active === 1 ? 'menu active' : 'menu'" @click="clickMenu(1)">贸易大厅</div>
          <div :class="active === 2 ? 'menu active' : 'menu'" @click="clickMenu(2)">中非商城</div>
          <div :class="active === 3 ? 'menu active' : 'menu'" @click="clickMenu(3)">供应链服务</div>
          <div :class="active === 4 ? 'menu active' : 'menu'" @click="clickMenu(4)">行业资讯</div>
          <div :class="active === 5 ? 'menu active' : 'menu'" @click="clickMenu(5)">关于我们</div>
        </div>
      </div>
      <div class="user-container">
        <div class="app-wrap btn" @click="warnMessage">
          <img class="app" src="@/assets/images/app.png" alt="" />
          <div class="app-text">APP下载</div>
        </div>
        <a-button class="btn" type="primary" @click="warnMessage">立即注册</a-button>
        <a-button class="btn" type="primary" ghost @click="warnMessage">登录</a-button>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';

export default {
  name: 'App',
  data() {
    return {
      appWidth: 1920,
      ratio: 1,
      active: 0,
    };
  },
  created() {
    this.adjustSize();
  },
  methods: {
    adjustSize() {
      this.ratio = window.innerWidth / this.appWidth;
    },
    warnMessage() {
      message.warn('系统内测中, 敬请期待');
    },
    clickMenu(index) {
      console.log(index);
      if (index !== 0) {
        this.warnMessage();
      } else {
        this.active = index;
      }
    },
  },
};
</script>

<style lang="less">
::-webkit-scrollbar {
  display: none !important;
}

div {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

body,html {
  margin: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: SourceHanSansCN-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2E3033;
  width: 100%;
  height: 100%;
  .main {
    position: relative;
    transform-origin: 0 0;
    transition: all .3s linear;
    .header {
      box-sizing: border-box;
      height: 70px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 0 40px;
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 0;
      z-index: 100;
      .logo-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-family: alimama;
        font-weight: 700;
        box-sizing: border-box;
        margin-right: 70px;
        cursor: pointer;
        .logo {
          width: 32px;
          height: 32px;
          margin-right: 11px;
        }
        .title {
          color: #1B2443;
          font-size: 22px;
        }
      }
      .menu-container {
        flex: 1;
        font-family: PingFangSC-Regular;
        .menu-tag {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          height: 100%;
          transition: all .3s linear;
          .menu {
            box-sizing: border-box;
            height: 100%;
            margin: 0 25px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            &:hover {
              font-family: PingFangSC-Medium;
              color: #356DEE;
              font-weight: 500;
            }
          }
          .active {
            font-family: PingFangSC-Medium;
            color: #356DEE;
            font-weight: 500;
            border-bottom: 2px solid #356DEE;
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px;
          }
        }
      }
      .user-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .btn {
          margin-left: 20px;
        }
        .app-wrap {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          .app {
            width: 13px;
            height: 17px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
